// JsFromRoutes CacheKey 3d50f79da0801da120b669e01522b8a1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/buy/:funnel_slug/:offer_slug'),
  checkout: /* #__PURE__ */ definePathHelper(
    'get',
    '/secure/checkout/:funnel_slug/:checkout_id',
  ),
  placeOrder: /* #__PURE__ */ definePathHelper(
    'put',
    '/secure/checkout/:funnel_slug/:checkout_id',
  ),
  update: /* #__PURE__ */ definePathHelper(
    'put',
    '/secure/checkout/:funnel_slug/:checkout_id/update',
  ),
  updateItems: /* #__PURE__ */ definePathHelper(
    'post',
    '/secure/checkout/:funnel_slug/:checkout_id/update/items',
  ),
  postPurchaseOffer: /* #__PURE__ */ definePathHelper(
    'get',
    '/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id',
  ),
  acceptPostPurchaseOffer: /* #__PURE__ */ definePathHelper(
    'post',
    '/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id/accept',
  ),
  rejectPostPurchaseOffer: /* #__PURE__ */ definePathHelper(
    'post',
    '/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id/reject',
  ),
  mimicTracking: /* #__PURE__ */ definePathHelper(
    'post',
    '/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id/accept_offer',
  ),
  rejectPostPurchaseOfferWithTracking: /* #__PURE__ */ definePathHelper(
    'post',
    '/secure/checkout/:funnel_slug/:checkout_id/offer/:offer_id/reject_offer',
  ),
  loading: /* #__PURE__ */ definePathHelper(
    'get',
    '/secure/checkout/:funnel_slug/:checkout_id/loading',
  ),
  loadingStatus: /* #__PURE__ */ definePathHelper(
    'get',
    '/secure/checkout/:funnel_slug/:checkout_id/loading_status',
  ),
  confirmation: /* #__PURE__ */ definePathHelper(
    'get',
    '/secure/checkout/:funnel_slug/:checkout_id/complete',
  ),
  confirmationUpdate: /* #__PURE__ */ definePathHelper(
    'put',
    '/secure/checkout/:funnel_slug/:checkout_id/complete/update',
  ),
}
