// JsFromRoutes CacheKey 1da7595487f30928d6cf7da55a8d0a97
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  orderStatus: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/order_status',
  ),
  checkStatus: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/check_status',
  ),
}
