// JsFromRoutes CacheKey 4b483c513cc1644bf4a04be0b42b711a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  new: /* #__PURE__ */ definePathHelper('post', '/fixd_care/new'),
  show: /* #__PURE__ */ definePathHelper('get', '/fixd_care/s/:fixd_care_id'),
  showPayment: /* #__PURE__ */ definePathHelper(
    'get',
    '/fixd_care/p/:fixd_care_id',
  ),
  complete: /* #__PURE__ */ definePathHelper(
    'get',
    '/fixd_care/:fixd_care_id/complete',
  ),
  create: /* #__PURE__ */ definePathHelper('post', '/fixd_care'),
  generatePlan: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/request_offer',
  ),
  update: /* #__PURE__ */ definePathHelper('put', '/fixd_care/update'),
  survey: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/:fixd_care_id/survey',
  ),
  rejectOfferQuote: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/:fixd_care_id/reject',
  ),
  accept: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/:fixd_care_id/accept',
  ),
  chaizApiInformation: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/auth_token',
  ),
  updatePhoneNumber: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/submit_survey_ph',
  ),
  mimicContractGeneration: /* #__PURE__ */ definePathHelper(
    'post',
    '/fixd_care/submit_survey_info',
  ),
}
